import React from "react"
import PropTypes from "prop-types"
import { globalHistory as history } from "@reach/router"
import Seo from "./seo"
import { Link } from "gatsby"
import logo from "../images/logo.svg"
import SaveCookieParams from "../components/save_cookie_params"

const LayoutNoNav = ({ children }) => {

const { location, navigate } = history
  const urlParams = new URLSearchParams(location.search)

  if (
    urlParams.get("action") === "refer" &&
    urlParams.get("type") === "friend"
  ) {
    navigate(
      `${process.env.GATSBY_PORTAL_URL}/register/tenant${location.search}`
    )
    return
  }

return(
  <div className="font-sans bg-white pt-16">
    <Seo title="Layout" />
    <SaveCookieParams />

    <div className="fixed w-full top-0 bg-white z-50">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-3 md:justify-start md:space-x-10">
            <div className="lg:w-0 lg:flex-1">
              <Link to="/" className="flex">
                <img className="h-5 w-auto" src={logo} alt="logo" />
              </Link>
            </div>
          </div>
        </div>


      </div>

    <main>{children}</main>
  </div>
)}

LayoutNoNav.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutNoNav
