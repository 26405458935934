import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"
import CookiesModul from "../components/cookiesmodul"

import LayoutNoNav from "../components/layout_no_nav"
import logo from "../images/logo.svg"
import SEO from "../components/seo"
import { useUrlSearchParams } from "use-url-search-params";

export const query = graphql`
  query PartnerQuery($id: String!) {
    strapiPartner(strapiId: { eq: $id }) {
      strapiId
      partnerLogo {
        url
      }
      partnerCtaLink
      partnerVoucher
      partnerVoucherCode
      getFormUrl
      formIDNumber
      formNetIncome
      formLoanAmount
      formProductsCover
      formBestContactTime
      formAltMobile
      formUserType
      formInsure
      partnerCtaName
      partnerFormHeader
      mainSubHeading
      mainHeading
      mainDescription
      headerImage {
        url
      }
      partnerBrandColor
      sectionOneHeading
      sectionOneDescription
      sectionOneItems
      sectionOneImage {
        url
      }
      sectionHowItWorksHeading
      sectionHowItWorksDescription
      sectionHowItWorksItemOneHeading
      sectionHowItWorksItemOneDescription
      sectionHowItWorksItemTwoHeading
      sectionHowItWorksItemTwoDescription
      sectionHowItWorksItemThreeHeading
      sectionHowItWorksItemThreeDescription
      sectionTwoHeading
      sectionTwoDescription
      sectionTwoItems
      sectionTwoImage {
        url
      }
      partnerFooterText
      ogTitle
      ogDescription
      partnerTestimonial
      partnerTestimonialName
      partnerTestimonialTitle
      partnerTestimonialProfileImage {
        url
      }
      sectionOneVideoSwitch
      sectionOneVideoUrl
      sectionTwoVideoSwitch
      sectionTwoVideoUrl
      currentlyInsured
    }
  }
`

const Partner = ({ data }) => {
  const partner = data.strapiPartner
  const partnerBrandColor = "#" + partner.partnerBrandColor

  function handleClick(e) {
    console.log('Test');

    const currentForm = document.getElementById("partnerForm")

    if (currentForm.checkValidity() == true) {
      e.target.disabled = true;
      e.target.innerText = "Submitting...";
      currentForm.submit();
    }
  }

  const sectionOneItemsArray = partner.sectionOneItems.split("\n")
  const sectionOneItemsArrayMapped = sectionOneItemsArray.map(
    sectionOneItem => {
      return (
        <div className="w-full flex text-left mt-3">
          <div
            className="flex inline-block w-6 h-6 rounded-full text-white flex-shrink-0 text-center mr-6"
            style={{ backgroundColor: partnerBrandColor }}
          >
            <i className="w-full self-center text-xs fas fa-check"></i>
          </div>
          <div>
            <p className="font-medium text-gray-700 ">{sectionOneItem}</p>
          </div>
        </div>
      )
    }
  )

  const sectionTwoItemsArray = partner.sectionTwoItems.split("\n")
  const sectionTwoItemsArrayMapped = sectionTwoItemsArray.map(
    sectionTwoItem => {
      return (
        <div className="w-full flex text-left mt-3">
          <div
            className="flex inline-block w-6 h-6 rounded-full text-white flex-shrink-0 text-center mr-6"
            style={{ backgroundColor: partnerBrandColor }}
          >
            <i className="w-full self-center text-xs fas fa-check"></i>
          </div>
          <div>
            <p className="font-medium text-gray-700 ">{sectionTwoItem}</p>
          </div>
        </div>
      )
    }
  )

  const [params] = useUrlSearchParams({});

  return (
    <LayoutNoNav>
      <SEO
        title={partner.ogTitle}
        description={partner.ogDescription}
        image={partner.partnerLogo.url}
      />

      <section className="w-full md:px-10 relative bg-gray-100 md:pt-12 md:pb-24">
        <div className="max-w-4xl mx-auto text-center md:text-left pt-5 md:pt-0">  
          <a href={`/partners`} target="_blank"><span className="text-sm text-gray-500 underline">Partners</span></a>
          <span className="text-sm text-gray-400"> / </span><span className="text-sm text-gray-800 font-medium">{partner.mainSubHeading}</span>
        </div>
        <div className="max-w-4xl mx-auto py-5">
          <header className="flex flex-col md:flex-row w-full top-0">
            <div className="self-center w-full md:w-1/2 md:mt-0 px-5 md:px-0">
              <div className="text-center md:text-left">
                <img
                  src={partner.partnerLogo.url}
                  alt=""
                  className="block h-16 mx-auto md:mx-0"
                />

                <p className="text-md md:text-lg font-bold text-gray-700 mt-3">
                  Flow Partners: {partner.mainSubHeading}
                </p>
                <h1 className="text-3xl md:text-5xl mx-auto md:mx-0 max-w-sm text-gray-700 font-bold leading-none tracking-tight">
                  {partner.mainHeading}
                </h1>
                <p className="text-md md:text-xl mx-auto md:mx-0 max-w-sm font-light text-gray-400 mt-3">
                  {partner.mainDescription}
                </p>

                {partner.partnerVoucher && (
                  <div className="inline-flex bg-white shadow-xl rounded-xl p-2 mt-5">
                    <div className="flex bg-gray-200 font-bold tracking-widest text-gray-700 rounded-lg px-3 mr-2">
                      <span className="self-center">
                        {partner.partnerVoucherCode}
                      </span>
                    </div>
                    <a
                      href={partner.partnerCtaLink}
                      className="inline-block text-white rounded-lg p-3 cursor-pointer"
                      style={{ backgroundColor: partnerBrandColor }}
                    >
                      {partner.partnerCtaName}
                    </a>
                  </div>
                )}
              </div>
            </div>

            <div className="w-full md:w-1/2 px-10">
              {partner.partnerVoucher ? (
                <img src={partner.headerImage.url} alt="" className="w-full rounded-xl mt-10 md:mt-0" />
              ) : (
                
                  <form 
                    action={partner.getFormUrl}
                    method="POST"
                    id="partnerForm"
                    className="bg-white shadow-xl rounded-xl p-5"
                  >

                    <h2 className="font-bold text-md text-gray-700">{partner.partnerFormHeader}</h2>

                    <input placeholder="First Name" type="text" name="First Name" required className="bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2"></input>
                    <input placeholder="Surname" type="text" name="Surname" required className="bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2"></input>
                    <input placeholder="Email" type="email" name="Email" required className="bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2"></input>
                    <input placeholder="Mobile" type="text" inputmode="numeric" pattern="[0-9]{10}" name="Mobile" required className="bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2"></input>
                
                    <input placeholder="utm_source" type="text" name="utm_source" className="hidden" value={params.utm_source}></input>
                    <input placeholder="utm_medium" type="text" name="utm_medium" className="hidden" value={params.utm_medium}></input>
                    <input placeholder="utm_campaign" type="text" name="utm_campaign" className="hidden" value={params.utm_campaign}></input>
                    <input placeholder="utm_content" type="text" name="utm_content" className="hidden" value={params.utm_content}></input>

                    {partner.formAltMobile ? (
                      <input placeholder="Alternate Contact" type="text" inputmode="numeric" pattern="[0-9]{10}" name="Alternate Contact" className="bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2"></input>
                    ) : ''}

                    {partner.formIDNumber ? (
                      <input placeholder="ID Number" type="text" inputmode="numeric" pattern="[0-9]{13}" minlength="13" name="ID Number" required className="bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2"></input>
                    ) : ''}

                    {partner.formNetIncome ? (
                    <input placeholder="Net Income" type="number" min="0" name="Net Income" required className="bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2"></input>
                    ) : ''}
                    
                    {partner.formLoanAmount ? (
                    <input placeholder="Loan Amount" type="number" min="0" name="Loan Amount" required className="bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2"></input>
                    ) : ''}
                    
                    {partner.formUserType ? (
                      <div className="relative">
                        <select name="User Type" className="appearance-none bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2">
                          <option value="" disabled selected>I am:</option>
                          <option value="Buying a property">Buying a property</option>
                          <option value="Selling a property">Selling a property</option>
                          <option value="I'm an agent">I'm an agent</option>
                        </select>
                        <div class="pointer-events-none absolute inset-y-0 right-0 mt-2 flex items-center px-4 text-gray-700">
                          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
                        </div>
                      </div>
                    ) : ''}

                    {partner.formBestContactTime ? (
                      <div className="relative">
                        <select name="Best Contact Time" className="appearance-none bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2">
                          <option value="" disabled selected>Best Contact Time?</option>
                          <option value="Business hours">Business hours</option>
                          <option value="After hours">After hours</option>
                        </select>
                        <div class="pointer-events-none absolute inset-y-0 right-0 mt-2 flex items-center px-4 text-gray-700">
                          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
                        </div>
                      </div>
                    ) : ''}

                    {partner.formProductsCover ? (
                    <div className="relative">
                      <select name="Products to Cover" className="appearance-none bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2">
                        <option value="" disabled selected>Products to Cover?</option>
                        <option value="Electronics">Electronics</option>
                        <option value="Kitchen Appliances">Kitchen appliances</option>
                        <option value="Stove">Stove</option>
                        <option value="Fridges">Fridges</option>
                        <option value="Other">Other</option>
                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 mt-2 flex items-center px-4 text-gray-700">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
                      </div>
                    </div>
                    ) : ''}

                    {partner.formInsure ? (
                      <div className="relative">
                        <select name="Ensure" className="appearance-none bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2">
                          <option value="" disabled selected>What would you like to insure?</option>
                          <option value="Home contents">Home contents</option>
                          <option value="Building">Building</option>
                          <option value="Car">Car</option>
                          <option value="Other">Other</option>
                        </select>
                        <div class="pointer-events-none absolute inset-y-0 right-0 mt-2 flex items-center px-4 text-gray-700">
                          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
                        </div>
                      </div>
                    ) : ''}

                    {partner.currentlyInsured ? (
                      <div className="relative">
                        <select name="currentlyInsured" required className="appearance-none bg-gray-100 text-gray-800 border-0 border-gray-200 font-medium text-sm p-3 rounded-lg w-full mt-2">
                          <option value="" disabled selected>Are you currently insured?</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <div class="pointer-events-none absolute inset-y-0 right-0 mt-2 flex items-center px-4 text-gray-700">
                          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
                        </div>
                      </div>
                    ) : ''}

                    <p className="text-xxs text-gray-500 mt-4">By submitting I agree to allow Flow to collect, store and process my data, as per the <a href="/privacypolicy" target="_blank" className="text-orange-500 underline">Privacy Policy</a></p>

                    <button 
                      type="submit"
                      onClick={handleClick}
                      className="inline-block text-white rounded-lg p-3 cursor-pointer mt-5"
                      style={{ backgroundColor: partnerBrandColor }}
                    >{partner.partnerCtaName}
                    </button>

                  </form>
              )}
            </div>
          </header>
        </div>
      </section>

      <section
        id="features"
        className="mt-12 md:mt-0 py-10 md:py-32 px-5 mx-5 md:mx-10 rounded-xl"
      >
        <div className="max-w-4xl mx-auto flex flex-col-reverse md:flex-row">
          <div className="w-full md:w-1/2 text-center md:text-left">
            <h2 className="text-2xl md:text-4xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
              {partner.sectionOneHeading}
            </h2>
            <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4 mb-10">
              {partner.sectionOneDescription}
            </p>

            {sectionOneItemsArrayMapped}
          </div>
          <div className="flex w-full md:w-1/2">

          {partner.sectionOneVideoSwitch ? (
            <iframe className="block self-center mx-auto w-full md:w-4/5 h-48 md:h-72 mb-10 md:mb-0 rounded-xl"
              src={partner.sectionOneVideoUrl}>
            </iframe>
          ) : (
            <img
              className="self-center mx-auto w-full md:w-2/3 mb-10 md:mb-0 rounded-xl"
              src={partner.sectionOneImage.url}
              alt=""
            />           
          )}
            
          </div>
        </div>
      </section>

      <section className="p-5 md:p-0">
        <div className="container mx-auto p-10 rounded-xl bg-gray-100 px-10 md:py-24 items-center flex text-center md:text-left">
          <div className="container mx-auto">
            <div className="w-full md:text-center">
              <h2 className="text-2xl md:text-4xl font-bold text-gray-700 leading-none">
                {partner.sectionHowItWorksHeading}
              </h2>
              <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto">
                {partner.sectionHowItWorksDescription}
              </p>
            </div>

            <div className="flex flex-wrap mt-5">
              <div className="w-full flex md:flex-col md:w-1/3 md:px-3 text-center mt-5">
                <div
                  className="flex inline-block mx-auto w-10 h-10 rounded-full text-white flex-shrink-0 text-center"
                  style={{ backgroundColor: partnerBrandColor }}
                >
                  <span className="w-full self-center">01</span>
                </div>
                <div className="flex-1 text-left md:text-center ml-5 md:ml-0">
                  <h3 className="font-bold text-gray-700 md:mt-2">
                    {partner.sectionHowItWorksItemOneHeading}
                  </h3>
                  <p className="font-light text-sm text-gray-400">
                    {partner.sectionHowItWorksItemOneDescription}
                  </p>
                </div>
              </div>
              <div className="w-full flex md:flex-col md:w-1/3 md:px-3 text-center mt-5">
                <div
                  className="flex inline-block mx-auto w-10 h-10 rounded-full text-white flex-shrink-0 text-center"
                  style={{ backgroundColor: partnerBrandColor }}
                >
                  <span className="w-full self-center">02</span>
                </div>
                <div className="flex-1 text-left md:text-center ml-5 md:ml-0">
                  <h3 className="font-bold text-gray-700 md:mt-2">
                    {partner.sectionHowItWorksItemTwoHeading}
                  </h3>
                  <p className="font-light text-sm text-gray-400">
                    {partner.sectionHowItWorksItemTwoDescription}
                  </p>
                </div>
              </div>
              <div className="w-full flex md:flex-col md:w-1/3 md:px-3 text-center mt-5">
                <div
                  className="flex inline-block mx-auto w-10 h-10 rounded-full text-white flex-shrink-0 text-center"
                  style={{ backgroundColor: partnerBrandColor }}
                >
                  <span className="w-full self-center">03</span>
                </div>
                <div className="flex-1 text-left md:text-center ml-5 md:ml-0">
                  <h3 className="font-bold text-gray-700 md:mt-2">
                    {partner.sectionHowItWorksItemThreeHeading}
                  </h3>
                  <p className="font-light text-sm text-gray-400">
                    {partner.sectionHowItWorksItemThreeDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="benefits"
        className="mt-12 md:mt-0 py-10 md:py-32 px-5 mx-5 md:mx-10 rounded-xl"
      >
        <div className="container mx-auto flex flex-col md:flex-row">
          <div className="flex w-full md:w-1/2">
            {partner.sectionTwoVideoSwitch ? (
              <iframe className="block self-center mx-auto w-full md:w-4/5 h-48 md:h-72 mb-10 md:mb-0 rounded-xl"
                src={partner.sectionTwoVideoUrl}>
              </iframe>
            ) : (
              <img
                className="self-center mx-auto w-full md:w-2/3 mb-10 md:mb-0 rounded-xl"
                src={partner.sectionTwoImage.url}
                alt=""
              />           
            )}
          </div>
          <div className="w-full md:w-1/2 text-center md:text-left">
            <h2 className="text-2xl md:text-4xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
              {partner.sectionTwoHeading}
            </h2>
            <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4 mb-10">
              {partner.sectionTwoDescription}
            </p>

            {sectionTwoItemsArrayMapped}
          </div>
        </div>
      </section>

      <section className="container mx-auto px-5 md:px-0">
        <div className="bg-gray-100 w-full rounded-xl p-8 md:p-24">
          <p className="font-medium tracking-tight text-gray-700 text-xl md:text-3xl">
            “{partner.partnerTestimonial}”
          </p>
          <div className="inline-flex mt-4 leading-none">
            <img className="w-10 h-10 mr-2" src={partner.partnerTestimonialProfileImage.url} alt="" />
            <div className="mt-1 text-left">
              <p className="">{partner.partnerTestimonialName}</p>
              <p className="text-sm text-gray-400 mt-1">{partner.partnerTestimonialTitle}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="partnerFooterText container mx-auto p-5 md:p-0 mt-10 md:mt-20">
        <p className="text-3xl md:text-6xl leading-tight font-bold text-gray-400 leading-none text-center">
          <ReactMarkdown source={partner.partnerFooterText} />
        </p>

        {partner.partnerVoucher && (
          <div className="flex justify-center mt-10">
            <div className="inline-flex bg-white shadow-xl rounded-xl p-2 mt-5">
              <div className="flex bg-gray-200 font-bold tracking-widest text-gray-700 rounded-lg px-3 mr-2">
                <span className="self-center">
                  {partner.partnerVoucherCode}
                </span>
              </div>
              <a
                href={partner.partnerCtaLink}
                className="inline-block text-white rounded-lg p-3 cursor-pointer"
                style={{ backgroundColor: partnerBrandColor }}
              >
                {partner.partnerCtaName}
              </a>
            </div>
          </div>
        )}
      </section>

    <footer className="container mx-auto">
      <div className="px-4 md:px-10 py-24 text-center">
        <img className="h-4 mx-auto" src={logo} alt="" />
        <p className="w-full text-xs text-gray-400 mt-4">
          Copyright © 2021 Flow Living (PTY) Ltd. All rights reserved. <br />
          <a 
            href="/termsofuse" 
            target="_blank"
            className="underline mx-1"
          >
            Terms of use
          </a>
          <a 
              href="/privacypolicy" 
              target="_blank"
              className="underline mx-1">
              Privacy Policy
            </a>
        </p>
      </div>
    </footer>
    <CookiesModul />
    </LayoutNoNav>
  )
}

export default Partner
